<template>
  <v-snackbar
    :key="$route.path"
    v-model="hasText"
    multi-line
    :offset="50"
    location="center"
    color="red"
  >
    {{ text }}
  </v-snackbar>
</template>
<script setup lang="ts">
import { useSnackbar } from '@/composables/use-snackbar';
import { computed } from 'vue';
const hasText = computed(() => !!text.value);
const { text } = useSnackbar();
</script>