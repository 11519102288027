import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { useVisitConditionsStore } from './visit-conditions';
import type { IGroup } from '@einfachgast/shared';

export const useGroupsStore = defineStore(
  'groups',
  () => {
    const visitConditionsStore = useVisitConditionsStore();
    const availableGroups = computed<Array<IGroup>>(() => JSON.parse(JSON.stringify(visitConditionsStore.visitSettings?.groups || [])));

    const defaultGroup = computed(() => (availableGroups.value.find(x => x.isDefaultGroup) ?? availableGroups.value[0]) as IGroup);

    const _selectedGroup = ref<IGroup | null>(JSON.parse(JSON.stringify(defaultGroup.value || {})));
    const selectedGroup = computed(() => _selectedGroup.value);

    const selectGroup = (group: IGroup) => _selectedGroup.value = group;

    const sanitizeGroups = () => {
      if (!availableGroups.value?.map(x => x.id).includes(selectedGroup.value?.id)) {
        _selectedGroup.value = defaultGroup.value;
      }
    };

    const clear = () => {
      _selectedGroup.value = null;
    };

    return { _selectedGroup, selectedGroup, availableGroups, defaultGroup, selectGroup, clear, sanitizeGroups };
  },
  {
    persist: {
      // This removes persisted entries for Groups that are not present in the Visit Settings anymore
      afterRestore: (ctx) => {
        ctx.store.sanitizeGroups();
        ctx.store.$persist();
      },
      debug: true,
    },
  },
);
