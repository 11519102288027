import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import type { IVisitDocument, VisitInputFieldValues, ITranslateableText, VisitStatus, IVisitFormInputFieldsSection, IVisitFormInputField, ISelectInputData, IVisitCheckInSuccessResponse } from '@einfachgast/shared';
import { useVisitConditionsStore } from './visit-conditions';
import { useGroupsStore } from './groups';
import type { CheckinVisitFragment } from '@/types/checkin-visit-fragment';
import { containsOption, containsOptions } from '../helpers/select-options-helper';
import { VisitApi } from '../api/visit-api';

export const useVisitStore = defineStore(
  'visit',
  () => {
    const visitConditionsStore = useVisitConditionsStore();
    const groupsStore = useGroupsStore();
    const visitApi = new VisitApi();

    const _hasCheckedOut = ref(false);
    const hasCheckedOut = computed(() => _hasCheckedOut.value);
    const clearCheckedOutState = () => _hasCheckedOut.value = false;

    const _checkinDenied = ref(false);
    const checkinDenied = computed(() => _checkinDenied.value);
    const setCheckinDenied = (value: boolean) => _checkinDenied.value = value;

    const _pendingVisitVisitorIdCardBase64 = ref<string | null>(null);
    const pendingVisitVisitorIdCardBase64 = computed(() => _pendingVisitVisitorIdCardBase64.value);

    const _pendingVisitToken = ref<string | null>(null);
    const pendingVisitToken = computed(() => _pendingVisitToken.value);
    const isCheckedIn = computed(() => !!_pendingVisitToken.value);

    const _documents = ref<Array<IVisitDocument>>([]);
    const documents = computed(() => _documents);

    const _photo = ref<string>();
    const photo = computed(() => _photo);
    const clearPhoto = () => _photo.value = undefined;

    const _inputFields = ref<Array<{fieldId: string, value: VisitInputFieldValues}>>([]);
    const inputFields = computed(() => _inputFields.value);

    const _name = ref<string>('');
    const name = computed(() => _name.value);

    const setName = (name: string) => {
      _name.value = name;
    };

    /**
     * This function removes values Of inputFields that are not present in the visitFormSchema anymore
     * And selected Options that are no longer present in the visitFormSchema
     */
    const sanitzieInputFields = () => {
      // we do not touch pending visits shit...
      if (isCheckedIn.value) {
        return;
      }
      // find input fields section
      const inputFieldsSection = (visitConditionsStore.visitForm?.sections || []).find(x => x.type === 'inputFields') as IVisitFormInputFieldsSection;

      if (!inputFieldsSection) {
        return;
      }

      // loop through all input fields and clean up data
      (inputFields.value).forEach((field, index) => {
        const fieldDefinition = inputFieldsSection.inputFields.find(x => x.id === field.fieldId);
        // if Field does not exist in visitForms input field definitions, Delete it
        if (!fieldDefinition) {
          console.log(`deleting stored value for field ${field.fieldId} because field is not present in formSchema`);
          inputFields.value.splice(index, 1);
        }
        const options = (fieldDefinition as IVisitFormInputField<ISelectInputData>)?.data?.options;

        // if Select field Value does not exist in fieldDefition otions, delete it
        if (fieldDefinition?.type === 'select') {
          // invalid option selected. delete field...
          if (!containsOption(options, field.value as string)) {
            console.log(`deleting storef value for field ${field.fieldId} because ${JSON.stringify(field.value)} is not a valid option`);
            inputFields.value.splice(index, 1);
          }
        }

        // if MultiSelect field Value does not exist in fieldDefition otions, delete it
        if (fieldDefinition?.type === 'multiselect') {
          // invalid option selected. delete field...
          if (!containsOptions(options, field.value as Array<string>)) {
            console.log(`deleting storef value for field ${field.fieldId} because ${JSON.stringify(field.value)} is not a valid option`);
            inputFields.value.splice(index, 1);
          }
        }
      });
    };

    const getFieldConf = (fieldId : string) => {
      return getInputFieldsSection()?.inputFields?.find(x => x.id === fieldId);
    };

    const getInputFieldsSection = () => {
      return (visitConditionsStore.visitForm?.sections || []).find(x => x.type === 'inputFields') as IVisitFormInputFieldsSection;
    };

    const getNameField = () => {
      return getInputFieldsSection()?.inputFields?.find(x => x.fixed === true);
    };

    const getGroupId = () => {
      return groupsStore?.selectedGroup?.id || // Either the selected group
        visitConditionsStore?.visitSettings?.groups?.find(x => x.isDefaultGroup)?.id || // or the default group
        visitConditionsStore?.visitSettings?.groups[0]?.id; // If No default group is set, the first group in the list
    };

    const checkin = async (status = 'pending', statusMessage?: ITranslateableText) => {
      const visit: CheckinVisitFragment = {
        name: inputFields.value.find(x => x.fieldId === getNameField()?.id)?.value as string,
        inputFields: inputFields.value,
        documents: documents.value.value,
        group: getGroupId() as string,
        status: status as VisitStatus,
        statusMessage,
      };

      let checkinResult;
      try {
          checkinResult = await visitApi.checkin({visit, token: visitConditionsStore?.visitToken, photo: hasPhotoSection() ? photo.value.value : undefined });
      } finally {
        // documents must be cleared after checkin, but i still want an Exception to be thrown if checkin fails
        _documents.value = [];
      }

      // Denied checkins save no token, because we dont need to check them out and dont want to have them in login state
      if (status !== 'denied') {
        _pendingVisitToken.value = checkinResult.token as string;
      }
      if (checkinResult.visitorIdCardBase64) {
        _pendingVisitVisitorIdCardBase64.value = checkinResult.visitorIdCardBase64;
      }
    };

    const hasPhotoSection = () => {
      return (visitConditionsStore.visitForm?.sections || []).find(x => x.type === 'photo');
    };

    const checkout = async () => {
      await visitApi.checkout(pendingVisitToken.value as string);
      _pendingVisitToken.value = null;
      _hasCheckedOut.value = true;
      _pendingVisitVisitorIdCardBase64.value = null;
    };

    const clearPendingVisit = () => {
      _pendingVisitToken.value = null;
      _pendingVisitVisitorIdCardBase64.value = null;
    };

    const clear = () => {
      _documents.value = [];
      _inputFields.value = [];
      _name.value = '';
    };

    return {
      isCheckedIn,
      _hasCheckedOut,
      hasCheckedOut,
      inputFields,
      _inputFields,
      _documents,
      documents,
      _pendingVisitToken,
      pendingVisitToken,
      _pendingVisitVisitorIdCardBase64,
      pendingVisitVisitorIdCardBase64,
      _name,
      name,
      _photo,
      photo,
      _checkinDenied,
      checkinDenied,
      setCheckinDenied,
      clear,
      clearPhoto,
      checkin,
      sanitzieInputFields,
      clearCheckedOutState,
      clearPendingVisit,
      checkout,
      setName,
      getInputFieldsSection,
      getNameField,
      getFieldConf,
    };
  },
  {
    persist: {
      // This removes persisted entries for inputFields that are not present in the visitFormSchema anymore
      // And selected Options that are no longer present in the visitFormSchema
      afterRestore: (ctx) => {
        ctx.store.sanitzieInputFields();
        ctx.store.$persist();
      },
      debug: true,
    },
  },
);
