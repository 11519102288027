import { createRouter, createWebHistory } from 'vue-router';
import { RouteName } from './route-name';
import { useVisitStore } from '../store/visit';
import SplashScreen from '@/views/SplashScreen.vue';
import CheckedOutView from '@/views/CheckedOutView.vue';

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: [
    {
      path: '/',
      redirect: { name: RouteName.InvalidQrCode },
    },
    {
      path: '/qr/:qrCodeId',
      name: RouteName.Loading,
      component: SplashScreen, // have to load here static cause iOs And Firefox have problems with dynamic imports
      props: true,
    },
    {
      path: '/checkin/:step',
      name: RouteName.Checkin,
      component: async () => import('@/views/CheckinView.vue'),
      props: true,
    },
    {
      path: '/checkedIn',
      name: RouteName.CheckedIn,
      component: async () => import('@/views/CheckedInView.vue'),
      props: true,
    },
    {
      path: '/checkingIn',
      name: RouteName.CheckingIn,
      component: async () => import('@/views/CheckingInView.vue'),
    },
    {
      path: '/checkedOut',
      name: RouteName.CheckedOut,
      component: CheckedOutView,
      props: true,
    },
    {
      path: '/checkinDenied/:reason?',
      name: RouteName.CheckinDenied,
      component: async () => import('@/views/CheckinDeniedView.vue'),
      props: true,
    },
    {
      path: '/invalid-code',
      name: RouteName.InvalidQrCode,
      component: async () => import('@/views/InvalidQrCodeview.vue'),
    },
    {
      path: '/:catchAll(.*)',
      redirect: { name: RouteName.InvalidQrCode },
    },
  ],
});

router.beforeEach((to, from, next) => {
  const visitStore = useVisitStore();

  // User has checked out. Prevent errors by redirecting to checked out page
  // until a new qrCode is scanned
  if (visitStore.hasCheckedOut && ![RouteName.CheckedOut, RouteName.Loading].includes(to.name as RouteName)) {
    next({ name: RouteName.CheckedOut });
    return;
  }

  // Checkin was dienied. Prevent errors by redirecting to checked out page
  // until a new qrCode is scanned
  if (visitStore.checkinDenied && ![RouteName.CheckinDenied, RouteName.Loading].includes(to.name as RouteName)) {
    next({ name: RouteName.CheckinDenied });
    return;
  }

  // Checked in users always go to checked in page
  if (visitStore.isCheckedIn && to.name !== RouteName.CheckedIn) {
    next({ name: RouteName.CheckedIn });
    return;
  }

  // Users who are not checked in, cannot access checked in page
  if (!visitStore.isCheckedIn && to.name === RouteName.CheckedIn) {
    next({ name: RouteName.CheckedOut });
    return;
  }

  next();
});

export default router;
