
import {  ref } from 'vue';
const text = ref<string>('');

const useSnackbar = () => {
  const hideSnackbar = () => {
    text.value = '';
  };
  const showSnackbar = (message: string, duration = 5000) => {
    text.value = message;
    setTimeout(() => {
      text.value = '';
    }, duration);
  };

  return { text, showSnackbar, hideSnackbar };
};

export { useSnackbar };