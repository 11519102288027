import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import { useVisitConditionsStore } from './visit-conditions';
import type { ITranslateableText, Languages } from '@einfachgast/shared';
import { getDefaultLanguage } from '@/helpers/default-language-helper';
import { useI18n } from 'vue-i18n';

export const systemLanguageCode = 'de';
export const useI18nStore = defineStore(
  'i18n',
  () => {
    const t = useI18n();
    const visitConditionsStore = useVisitConditionsStore();

    const _systemLanguage = ref<Languages>(systemLanguageCode);
    const systemLanguage = computed(() => _systemLanguage.value);

    const _selectedLanguage = ref<Languages>(systemLanguageCode);
    const selectedLanguage = computed(() => _selectedLanguage.value);

    const _availablelLanguages = computed(() => visitConditionsStore.visitSettings?.languageSettings.activeLanguages || []);
    const availableLanguages = computed(() => _availablelLanguages.value);

    function selectLanguage (language: Languages) {
      if (!availableLanguages.value.includes(language)) {
        throw new Error(`Language ${language} is not available`);
      }
      t.locale.value = language;
      _selectedLanguage.value = language;
    }

    function translateFromObject (translationObject: ITranslateableText) {
      if (!translationObject) {
        return '';
      }
      // hopefully: fix for issue https://merifond-new-markets-gmbh.sentry.io/issues/5399307272/?project=4506338264023040&referrer=github_integration
      // edge mobile :D
      const language  = _selectedLanguage?.value ? _selectedLanguage.value : systemLanguageCode;
      // get the translation for the selected language or get the systemLanugaeg fallback
      return isEmpty(translationObject[language]) 
        ? translationObject[systemLanguageCode] 
        : translationObject[language] || '';
    }

    function isEmpty (value: string) {
      return !value || value === '';
    }

    function translate (value: string | ITranslateableText) {
      if (typeof value === 'string') {
        return t.t(value);
      }
      return translateFromObject(value);
    }

    function sanitizeLanguage () {
      if (!selectedLanguage.value) {
        return;
      }

      if (!availableLanguages.value.includes(selectedLanguage.value)) {
        _selectedLanguage.value = getDefaultLanguage();
      }
    }

    return { selectLanguage, t, _availablelLanguages, availableLanguages, systemLanguage, selectedLanguage, _selectedLanguage, translateFromObject, sanitizeLanguage, translate };
  },
  {
    persist: {
      debug: true,
      // This removes persisted entries for Groups that are not present in the Visit Settings anymore
      afterRestore: (ctx) => {
        ctx.store.sanitizeLanguage();
        ctx.store.$persist();
      },
    },
  },
);
