// eslint-disable-next-line no-duplicate-imports
import type { IFirebaseFunctionsUrlResolver } from '../interfaces/api/i-firebase-functions-url-resolver';
import type { FirebaseFunctions } from '@einfachgast/shared';
import type { IHTTPProvider } from '../interfaces';
import { FirebaseFunctionsUrlResolver } from './firebase-functions-url-resolver';
import axios from 'axios';

export interface IFirebaseCloudFunctions {
  call <T1 = {[key: string]: unknown}, T2 = {[key: string]: unknown}> (functionName: FirebaseFunctions, payload: T1): Promise<T2>;
}
/**
 * This is a lighweight replacement of firebase.httpsCallable.call('functionName').
 * It enables you to call firebase functions in a convinient way without having to import
 * that riddicilously huge firebase-js-sdk
 */
class FirebaseCloudFunctions implements IFirebaseCloudFunctions {
  constructor (
    private _firebaseUrlResolver: IFirebaseFunctionsUrlResolver = new FirebaseFunctionsUrlResolver(),
    private _httpProvider: IHTTPProvider = axios,
  ) {}

  /**
   * Call a firebase cloud function
   * @param functionName the name of the cloud function you want to call
   * @param payload the Payload you want to send to the cloud function
   */
  async call <T1 = {[key: string]: unknown}, T2 = {[key: string]: unknown}> (functionName: FirebaseFunctions, payload: T1): Promise<T2> {
    const response = await this._httpProvider.post(this._firebaseUrlResolver.getFunctionUrlByName(functionName), { data: payload });
    return response.data.result;
  }
}
export { FirebaseCloudFunctions };
