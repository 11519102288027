class TestHelper {

  get isEndToEndTest () {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    return (!!window.Cypress);
  }

}

const testHelper = new TestHelper();

export { TestHelper, testHelper };