import { captureConsoleIntegration, extraErrorDataIntegration, httpClientIntegration } from '@sentry/integrations';
import * as Sentry from '@sentry/vue';
import type { Integration } from '@sentry/types';
import { EnvVarHelper } from './helpers/env-var-helper';
import type { App } from 'vue';
import type { Router } from 'vue-router';

const envHelper = new EnvVarHelper();

const initSentry = (app: App<Element>, router: Router) => {
  Sentry.init({
    app,
    dsn: envHelper.get('SENTRY_DSN'),
    environment: envHelper.get('ENVIRONMENT') || process.env.NODE_ENV,
    integrations: [
      captureConsoleIntegration() as Integration,
      extraErrorDataIntegration() as Integration,
      httpClientIntegration() as Integration,
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ['localhost', envHelper.get('SCANNER_URL')],
        routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      }),
      new Sentry.Replay({
        networkDetailAllowUrls: [new RegExp('loadVisitConditions$', 'gm')],
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
};

const addCheckinInformationToSentry = (token?: string) => {
  try {
    Sentry.setContext('scannerData', { token: token });
  } catch (e) {
    // this exception is swallowed on puropse
    // To prevent the app from crashing if adding additional information to Sentry fails
    console.log('error setting Sentry context', e);
  }
};

export { initSentry, addCheckinInformationToSentry };