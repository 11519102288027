<template>
  <div class="success-wrapper text-center">
    <v-icon
      :icon="mdiAccountArrowRight"
      :size="100"
      color="success"
      class="mb-4"
    />
    <h1 class="view-heading text-h5 mb-4">{{ $translate('thanksForYourVisit') }}</h1>
    <div class="checked-in-text">
      <p
        class="my-4"
      >
        {{ $translate('youCanCloseThisPageNow') }}
      </p>
    </div>
  </div>
</template>
<script setup lang="ts">
import { mdiAccountArrowRight } from '@mdi/js';
import { onMounted } from 'vue';
import { useVisitConditionsStore } from '@/store/visit-conditions';
import { useGroupsStore } from '@/store/groups';
const visitConditionsStore = useVisitConditionsStore();
const groupsStore = useGroupsStore();
onMounted(() => {
  visitConditionsStore.clear();
  groupsStore.clear();
});

</script>
<style lang="scss" scoped>
.success-wrapper {
  margin-top: 33vh;
}
</style>