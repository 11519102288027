import type { FirebaseFunctions, IVisitConditions, ScannerVisitSettings } from '@einfachgast/shared';
import type { IVisitConditionsApi } from '../interfaces';
import { FirebaseCloudFunctions, type IFirebaseCloudFunctions } from './firebase-cloud-functions';

export class VisitConditionsApi implements IVisitConditionsApi {
  constructor (private _firebaseFunctions: IFirebaseCloudFunctions = new FirebaseCloudFunctions()){}

  async load (qrCodeId: string): Promise<IVisitConditions<ScannerVisitSettings>> {
    return await this._firebaseFunctions.call<{ qrCodeId: string }, IVisitConditions<ScannerVisitSettings>>('loadVisitConditions' as FirebaseFunctions, { qrCodeId });
  }
}
