import type { IEnvVarHelper, IFirebaseFunctionsUrlResolver } from '../interfaces';
import type { FirebaseFunctions } from '@einfachgast/shared';
import { EnvVarHelper } from '../helpers/env-var-helper';

export class FirebaseFunctionsUrlResolver implements IFirebaseFunctionsUrlResolver {
  private _baseUrl = '';

  constructor (private _envVarHelper: IEnvVarHelper = new EnvVarHelper()) {
    this._baseUrl = this._envVarHelper.get('FIREBASE_FUNCTIONS_BASE_URL');
  };

  getFunctionUrlByName (name: FirebaseFunctions): string {
    return `${this._baseUrl}/${name}`;
  }
}