import type { IEnvVarHelper } from '../interfaces';

/**
 *  Get values from process.env
 *  @see: https://vitejs.dev/guide/env-and-mode.html
 *
 */


export class EnvVarHelper implements IEnvVarHelper {
    constructor (
      private _vars: Record<string, string> = import.meta.env,
    ) {
      this._vars = _vars;
    }

    get (name: string, fallback = '') {
      return this._vars[`VITE_${this.normalize(name)}`] || fallback;
    }

    getArray (name: string, fallback = '') {
      const value = this.get(name, fallback);
      return value.split('|');
    }

    // Convert "MyAwesomeString" to "MY_AWESOME_STRING"
    private normalize (name: string) {
      const r = /([a-z])([A-Z])/g; // Matches lowercase letter followed by uppercase letter
      return name
        .replace(r, '$1_$2')
        .split('_')
        .filter(x => x.trim())
        .join('_')
        .toUpperCase();
    }
}
