import type { ITranslateableText } from '@einfachgast/shared';
import type { App } from 'vue';
import { useI18nStore } from '@/store/i18n';

const translate = (object: ITranslateableText | string) => {
  const i18nStore = useI18nStore();
  return i18nStore.translate(object);
};
export default {
  install: (app: App) => {
    app.config.globalProperties.$translate = translate;
  },
};
export { translate };