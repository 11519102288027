const pl = {
  'welcomeScreenHeading': 'Witaj',
  'requiredCheckboxMessage': 'Proszę potwierdzić pole wyboru',
  'pleaseSelect': 'Proszę wybrać...',
  'cancel': 'Anuluj',
  'ok': 'OK',
  'yes':'Tak',
  'back': 'Powrót',
  'welcome': 'Witaj',
  'no':'Nie',
  'pleaseEnter': 'Proszę wprowadzić...',
  'thanksForYourCheckin': 'Dziękujemy za zarejestrowanie się.',
  'checkoutConfirmTitle': 'Potwierdź wylogowanie',
  'checkoutConfirmMessage': 'Prosimy o wylogowanie się po zakończeniu wizyty.',
  'yesCheckout': 'Wyloguj teraz',
  'de': 'Deutsch',
  'en': 'English',
  'es': 'Español',
  'fr': 'Français',
  'it': 'Italiano',
  'pt': 'Português',
  'jp': '日本語',
  'nl': 'Nederlands',
  'pl': 'Polski',
  'ru': 'Русский',
  'tr': 'Türkçe',
  'cn': '中国人',
  'hu': 'Magyarország',
  'cz': 'Česká republika',
  'si': 'Slovenija',
  'ua': 'Україна (Ukrayina)',
  'ro': 'România',
  'hr': 'Hrvatska',
  'thanksForYourVisit': 'Dziękujemy za wizytę.',
  'youCanCloseThisPageNow': 'Możesz teraz zamknąć tę stronę.',
  'checkedOutViewTitle': 'Jesteś wylogowany',
  'checkoutMessage': 'Trwa wylogowywanie...',
  'checkout': 'Wylogować się',
  'invalidNameFormat': 'Proszę podać pełne imię i nazwisko.',
  'clickToSign': 'kliknij, aby podpisać',
  'invalidQrCodeHeading': 'Nieprawidłowy kod QR',
  'invalidQrCodeText': 'Proszę skontaktować się z personelem recepcji.',
  'checkedOut': 'Wylogowany',
  'checkinDeniedHeading': 'Niestety nie możemy Cię zarejestrować.',
  'next': 'Dalej',
  'groupSelectionHeading': 'Wybierz grupę odwiedzających:',
  'checkinMessage': 'Trwa rejestrowanie...',
  'aServiceOf': 'Usługa dostarczana przez',
  'checkedIn': 'Zarejestrowany',
  'CheckedInInfo': 'Proszę zachować tę stronę otwartą, aby się wylogować po zakończeniu wizyty.',
  'checkin': 'Zarejestruj',
  'delete': 'Usuń',
  'pleaseCheckYourInput': 'Proszę sprawdzić wprowadzone dane',
  'signAndProceed': 'Podpisz i kontynuuj',
  'signatureRequiredMessage': 'Proszę podpisać dokument',
  'requiredField': 'Pole wymagane',
  'invalidEmailMessage': 'Proszę wprowadzić prawidłowy adres e-mail',
  'pleaseEnterANumber': 'Proszę wprowadzić liczbę',
  'pleaseEnterAValidNumber': 'Proszę wprowadzić poprawną liczbę całkowitą',
  'invalidUrlMessage': 'Proszę podać poprawny adres URL, np. https://www.przykład.com',
  'functionsError.noSubscriptionFound': 'Aktualnie nie możemy Cię zarejestrować. Proszę skontaktować się z personelem recepcji.',
  'checkinLoading': 'Rejestrowanie...',
  'functionsError.denyOnValidationFailed': 'Nie możemy Cię zarejestrować z powodu błędnych danych. Proszę skontaktować się z personelem recepcji.',
  'functionsError.missingRequiredInput': 'Nie udało się zarejestrować. Nie wszystkie wymagane pola zostały wypełnione. Proszę skontaktować się z personelem recepcji.',
  'functionsError.invalidInputData': 'Wprowadzono nieprawidłowe dane. Proszę skontaktować się z personelem recepcji.',
  'functionsError.checkinTokenExpired': 'Twój link do rejestracji wygasł. Proszę zeskanować kod QR ponownie lub skontaktować się z personelem recepcji.',
  'startCamera': 'Dotknij, aby włączyć aparat.',
  'cameraLoading': 'Ładowanie...',
  'photoSectionTitle': 'Proszę zrób sobie zdjęcie w celu rejestracji.',
  'photoSectionDescription': 'Dostęp do aparatu i zdjęcia służą wyłącznie do weryfikacji Twojej tożsamości podczas rejestracji.',
  'takePhoto': 'Zrób zdjęcie',
  'reTakePhoto': 'Zrób ponownie zdjęcie',
  'cameraForbidden': 'Brak dostępu do aparatu.',
  'fullname': 'Pełne imię i nazwisko',
  'Details': 'Detale',
};

export { pl };
