const ro = {
  'welcomeScreenHeading': 'Bun venit',
  'requiredCheckboxMessage': 'Vă rugăm să confirmați caseta de selectare',
  'pleaseSelect': 'Vă rugăm să selectați…',
  'cancel': 'Anulați',
  'ok': 'OK',
  'yes':'Da',
  'back': 'Înapoi',
  'welcome': 'Bun venit',
  'no':'Nu',
  'pleaseEnter': 'Vă rugăm să introduceți…',
  'thanksForYourCheckin': 'Vă mulțumim pentru înregistrare.',
  'checkoutConfirmTitle': 'Confirmați deconectarea',
  'checkoutConfirmMessage': 'Vă rugăm să vă deconectați doar după ce ați terminat vizita.',
  'yesCheckout': 'Deconectează-te acum',
  'de': 'Deutsch',
  'en': 'English',
  'es': 'Español',
  'fr': 'Français',
  'it': 'Italiano',
  'pt': 'Português',
  'jp': '日本語',
  'nl': 'Nederlands',
  'pl': 'Polski',
  'ru': 'Русский',
  'tr': 'Türkçe',
  'cn': '中国人',
  'hu': 'Magyarország',
  'cz': 'Česká republika',
  'si': 'Slovenija',
  'ua': 'Україна (Ukrayina)',
  'ro': 'România',
  'hr': 'Hrvatska',
  'thanksForYourVisit': 'Vă mulțumim pentru vizită.',
  'youCanCloseThisPageNow': 'Puteți închide această pagină acum.',
  'checkedOutViewTitle': 'V-ați deconectat',
  'checkoutMessage': 'Vă deconectăm…',
  'checkout': 'Deconectare',
  'invalidNameFormat': 'Vă rugăm să introduceți numele complet.',
  'clickToSign': 'faceți clic pentru a semna',
  'invalidQrCodeHeading': 'Cod QR nevalid',
  'invalidQrCodeText': 'Vă rugăm să contactați personalul de recepție.',
  'checkedOut': 'Deconectat',
  'checkinDeniedHeading': 'Nu vă putem înregistra.',
  'next': 'Următor',
  'groupSelectionHeading': 'Selectați un grup de vizitatori:',
  'checkinMessage': 'Vă înregistrăm…',
  'aServiceOf': 'Un serviciu de',
  'checkedIn': 'Înregistrat',
  'CheckedInInfo': 'Vă rugăm să lăsați această pagină deschisă pentru a vă deconecta după ce ați terminat vizita.',
  'checkin': 'Înregistrare',
  'delete': 'Ștergeți',
  'pleaseCheckYourInput': 'Vă rugăm să verificați datele introduse',
  'signAndProceed': 'Semnează și continuă',
  'signatureRequiredMessage': 'Vă rugăm să semnați documentul',
  'requiredField': 'Câmp obligatoriu',
  'invalidEmailMessage': 'Vă rugăm să introduceți o adresă de e-mail validă',
  'pleaseEnterANumber': 'Vă rugăm să introduceți un număr',
  'pleaseEnterAValidNumber': 'Vă rugăm să introduceți un număr întreg valid',
  'invalidUrlMessage': 'Vă rugăm să introduceți o adresă URL validă, de exemplu: https://www.example.com',
  'functionsError.noSubscriptionFound': 'Momentan nu vă putem înregistra. Vă rugăm să contactați personalul de recepție.',
  'checkinLoading': 'Înregistrare…',
  'functionsError.denyOnValidationFailed': 'Nu vă putem înregistra din cauza datelor introduse. Vă rugăm să contactați personalul de recepție.',
  'functionsError.missingRequiredInput': 'Nu vă putem înregistra deoarece nu ați completat toate câmpurile obligatorii. Vă rugăm să contactați personalul de recepție.',
  'functionsError.invalidInputData': 'Ați introdus date nevalide. Vă rugăm să contactați personalul de recepție',
  'functionsError.checkinTokenExpired': 'Linkul de înregistrare a expirat. Vă rugăm să scanați din nou codul QR sau să contactați personalul de recepție.',
  'startCamera': 'Atingeți pentru a activa camera.',
  'cameraLoading': 'Se încarcă…',
  'photoSectionTitle': 'Vă rugăm să faceți o fotografie pentru a vă înregistra.',
  'photoSectionDescription': 'Accesul la cameră și fotografia sunt folosite exclusiv pentru verificarea identității dvs. în scopul înregistrării.',
  'takePhoto': 'Faceți o fotografie',
  'reTakePhoto': 'Refaceți fotografia',
  'cameraForbidden': 'Accesul la cameră este refuzat.',
  'fullname': 'Nume complet',
  'Details': 'Detalii',
};

export { ro };
