const jp = {
  'welcomeScreenHeading': 'ようこそ',
  'requiredCheckboxMessage': 'チェックボックスを確認してください',
  'pleaseSelect': '選択してください...',
  'cancel': 'キャンセル',
  'ok': 'OK',
  'yes':'はい',
  'back': '戻る',
  'welcome': 'ようこそ',
  'no':'いいえ',
  'pleaseEnter': '入力してください...',
  'thanksForYourCheckin': 'ご登録いただきありがとうございます。',
  'checkoutConfirmTitle': 'ログアウトを確認',
  'checkoutConfirmMessage': '訪問を終了したらログアウトしてください。',
  'yesCheckout': '今すぐログアウト',
  'de': 'Deutsch',
  'en': 'English',
  'es': 'Español',
  'fr': 'Français',
  'it': 'Italiano',
  'pt': 'Português',
  'jp': '日本語',
  'nl': 'Nederlands',
  'pl': 'Polski',
  'ru': 'Русский',
  'tr': 'Türkçe',
  'cn': '中国人',
  'hu': 'Magyarország',
  'cz': 'Česká republika',
  'si': 'Slovenija',
  'ua': 'Україна (Ukrayina)',
  'ro': 'România',
  'hr': 'Hrvatska',
  'thanksForYourVisit': 'ご訪問ありがとうございます。',
  'youCanCloseThisPageNow': 'このページを閉じても構いません。',
  'checkedOutViewTitle': 'ログアウトしました',
  'checkoutMessage': 'ログアウト中...',
  'checkout': 'ロサインアウト',
  'invalidNameFormat': '有効な名前を入力してください。',
  'clickToSign': 'クリックして署名',
  'invalidQrCodeHeading': '無効なQRコード',
  'invalidQrCodeText': '受付スタッフにお問い合わせください。',
  'checkedOut': 'ログアウトしました',
  'checkinDeniedHeading': '申し訳ありませんが、登録できません。',
  'next': '次へ',
  'groupSelectionHeading': '訪問者グループを選択してください:',
  'checkinMessage': '登録中...',
  'aServiceOf': 'のサービス',
  'checkedIn': '登録済み',
  'CheckedInInfo': '訪問終了時にログアウトするために、このページを開いたままにしてください。',
  'checkin': '登録',
  'delete': '削除',
  'pleaseCheckYourInput': '入力内容を確認してください',
  'signAndProceed': '署名して進む',
  'signatureRequiredMessage': 'ドキュメントに署名してください',
  'requiredField': '必須項目',
  'invalidEmailMessage': '有効なメールアドレスを入力してください',
  'pleaseEnterANumber': '数字を入力してください',
  'pleaseEnterAValidNumber': '有効な整数を入力してください',
  'invalidUrlMessage': '有効なURLを入力してください 例: https://www.example.com',
  'functionsError.noSubscriptionFound': '現在、登録できません。受付スタッフにお問い合わせください。',
  'checkinLoading': '登録中...',
  'functionsError.denyOnValidationFailed': '提供された情報が無効のため、登録できません。受付スタッフにお問い合わせください。',
  'functionsError.missingRequiredInput': '必須項目が入力されていないため、登録できません。受付スタッフにお問い合わせください。',
  'functionsError.invalidInputData': '無効な情報が提供されました。受付スタッフにお問い合わせください。',
  'functionsError.checkinTokenExpired': 'ログインリンクの有効期限が切れました。QRコードを再スキャンするか、受付スタッフにお問い合わせください。',
  'startCamera': 'カメラを起動するにはタップしてください。',
  'cameraLoading': '読み込み中...',
  'photoSectionTitle': '登録のために写真を撮影してください。',
  'photoSectionDescription': 'カメラへのアクセスと写真は、登録時の身元確認にのみ使用されます。',
  'takePhoto': '写真を撮る',
  'reTakePhoto': '再撮影',
  'cameraForbidden': 'カメラへのアクセスが拒否されました。',
  'fullname': 'フルネーム',
  'Details': '詳細',
};

export { jp };
