const cn = {
  'welcomeScreenHeading': '欢迎',
  'requiredCheckboxMessage': '请确认复选框',
  'pleaseSelect': '请选择...',
  'cancel': '取消',
  'ok': '确定',
  'yes':'是',
  'back': '返回',
  'welcome': '欢迎',
  'no':'否',
  'pleaseEnter': '请输入...',
  'thanksForYourCheckin': '感谢您的签到。',
  'checkoutConfirmTitle': '确认登出',
  'checkoutConfirmMessage': '请在结束您的访问后再进行登出。',
  'yesCheckout': '现在登出',
  'de': 'Deutsch',
  'en': 'English',
  'es': 'Español',
  'fr': 'Français',
  'it': 'Italiano',
  'pt': 'Português',
  'jp': '日本語',
  'nl': 'Nederlands',
  'pl': 'Polski',
  'ru': 'Русский',
  'tr': 'Türkçe',
  'cn': '中国人',
  'hu': 'Magyarország',
  'cz': 'Česká republika',
  'si': 'Slovenija',
  'ua': 'Україна (Ukrayina)',
  'ro': 'România',
  'hr': 'Hrvatska',
  'thanksForYourVisit': '感谢您的光临。',
  'youCanCloseThisPageNow': '您现在可以关闭此页面。',
  'checkedOutViewTitle': '您已登出',
  'checkoutMessage': '我们正在登出您...',
  'checkout': '退出',
  'invalidNameFormat': '请输入完整的姓名。',
  'clickToSign': '点击签名',
  'invalidQrCodeHeading': '无效的 QR 码',
  'invalidQrCodeText': '请联系前台工作人员。',
  'checkedOut': '已登出',
  'checkinDeniedHeading': '很抱歉，我们无法为您签到。',
  'next': '下一步',
  'groupSelectionHeading': '请选择访客组：',
  'checkinMessage': '我们正在为您签到...',
  'aServiceOf': '一项服务由',
  'checkedIn': '已签到',
  'CheckedInInfo': '请保持此页面打开，以便在访问结束后进行登出。',
  'checkin': '签到',
  'delete': '删除',
  'pleaseCheckYourInput': '请检查您的输入',
  'signAndProceed': '签名并继续',
  'signatureRequiredMessage': '请签署文件',
  'requiredField': '必填项',
  'invalidEmailMessage': '请输入有效的电子邮件地址',
  'pleaseEnterANumber': '请输入数字',
  'pleaseEnterAValidNumber': '请输入有效的整数',
  'invalidUrlMessage': '请输入有效的网址，例如 https://www.example.com',
  'functionsError.noSubscriptionFound': '我们当前无法为您签到。请联系前台工作人员。',
  'checkinLoading': '正在签到...',
  'functionsError.denyOnValidationFailed': '由于您提供的信息不完整，我们无法为您签到。请联系前台工作人员。',
  'functionsError.missingRequiredInput': '由于您未填写所有必填字段，我们无法为您签到。请联系前台工作人员。',
  'functionsError.invalidInputData': '您提供的信息无效。请联系前台工作人员。',
  'functionsError.checkinTokenExpired': '您的签到链接已过期。请重新扫描 QR 码或联系前台工作人员。',
  'startCamera': '点击以启动摄像头。',
  'cameraLoading': '正在加载...',
  'photoSectionTitle': '请拍摄您的照片以完成签到。',
  'photoSectionDescription': '仅用于验证您的身份以完成签到的摄像头访问和照片。',
  'takePhoto': '拍照',
  'reTakePhoto': '重新拍照',
  'cameraForbidden': '拒绝访问摄像头。',
  'fullname': '全名',
  'Details': '细节',
};

export { cn };
