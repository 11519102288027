import type { ISelectOption } from '@einfachgast/shared';

const containsOption = (options: ISelectOption[], optionId: string) => {
  return options.some(x => x.id === optionId);
};

const containsOptions = (options: ISelectOption[], optionIdsToCheck: string[]) => {
  return optionIdsToCheck.every(x => containsOption(options, x));
};

const removeInvalidOptions = (options: ISelectOption[], optionIdsToCheck: string[]) => {
  return (optionIdsToCheck ?? []).filter(x => containsOption(options, x));
};

export { containsOption, containsOptions, removeInvalidOptions };
