<script setup lang="ts">
import { onMounted } from 'vue';
import { RouterView } from 'vue-router';
import { useI18nStore } from './store/i18n';
import { useI18n } from 'vue-i18n';
import SnackBar from '@/components/SnackBar.vue';
const i18nStore = useI18nStore();
onMounted(() => {
  // init i18n Locale from store
  const { locale } = useI18n({ useScope: 'global' });
  locale.value = i18nStore.selectedLanguage;

});
</script>
<template>
  <v-app>
    <v-main>
      <RouterView />
      <SnackBar />
    </v-main>
  </v-app>
</template>
