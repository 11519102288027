<template>
  <div class="lds-roller"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
</template>

<script setup lang="ts">
import { onMounted } from 'vue';
import { useVisitConditionsStore } from '@/store/visit-conditions';
import router from '../router';
import { dynamicFormService } from '../dynamic-form/dynamic-form-service';
import { RouteName } from '../router/route-name';
import type { VisitFormSectionType } from '@einfachgast/shared';
import { useVisitStore } from '../store/visit';
import { useGroupsStore } from '../store/groups';
import { useI18nStore } from '@/store/i18n';

const visitStore = useVisitStore();
const groupStore = useGroupsStore();
const visitConditionsStore = useVisitConditionsStore();
const i18nStore = useI18nStore();

const props = defineProps({
  qrCodeId: { type: String, required: true },
});

onMounted(async () => {
  if (!props.qrCodeId) {
    router.replace({ name: RouteName.InvalidQrCode });
  }
  try {
    await visitConditionsStore.loadVisitConditions(props.qrCodeId);
    // reset Photo every time we scan a new QR code
    visitStore.clearPhoto();
    visitStore.sanitzieInputFields();
    visitStore.clearCheckedOutState();
    visitStore.setCheckinDenied(false);
    groupStore.sanitizeGroups();
    i18nStore.selectLanguage(visitConditionsStore.visitSettings?.languageSettings.defaultLanguage ?? 'de');
    router.replace({ name: RouteName.Checkin, params: { step: dynamicFormService.entryPoint as VisitFormSectionType } });
  } catch (e) {
    router.replace({ name: RouteName.InvalidQrCode });
  }
});
</script>