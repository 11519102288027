import { defineStore } from 'pinia';
import { ref, computed } from 'vue';
import type { ITranslateableText, IVisitForm, IVisitFormDocumentsSection, ScannerVisitSettings } from '@einfachgast/shared';
import { VisitConditionsApi } from '../api/visit-conditions-api';
import { addCheckinInformationToSentry } from '../sentry';

export type DenyOnValidationResult = { isValid: boolean, error?: ITranslateableText };
export type DenyOnValidator = () => true | ITranslateableText;

export const useVisitConditionsStore = defineStore(
  'visitConditions',
  () => {
    const visitConditionsApi = new VisitConditionsApi();

    const _visitSettings = ref<ScannerVisitSettings | null>(null);
    const visitSettings = computed(() => _visitSettings.value);

    const denyOnValidators = ref<Array<DenyOnValidator>>([]);
    const registerDenyOnValidator = (validator: DenyOnValidator) => denyOnValidators.value.push(validator);

    const executeDenyOnValidators = (): DenyOnValidationResult => {
      const result: DenyOnValidationResult = {
        isValid: true,
        error: undefined,
      };

      for (const validator of denyOnValidators.value) {
        const validationResult = validator();
        if (validationResult !== true) {
          result.isValid = false;
          result.error = validationResult;
          return result;
        }
      }

      return result;
    };

    const _visitToken = ref<string>('');
    const visitToken = computed(() => _visitToken.value);

    const _visitForm = ref<IVisitForm>({} as IVisitForm);
    const visitForm = computed(() => _visitForm.value);

    async function loadVisitConditions (qrCodeId: string) {
      const loadedResult = await visitConditionsApi.load(qrCodeId);

      _visitSettings.value = loadedResult.settings;
      _visitToken.value = loadedResult.token;

      // remove empty documents section
      const visitFormSchema = loadedResult.settings.visitFormSchema;
      const documentsSection = visitFormSchema.sections.find(x => x.type === 'documents') as IVisitFormDocumentsSection;
      if (documentsSection.documents?.length <= 0) {
        visitFormSchema.sections = visitFormSchema.sections.filter(x => x.type !== 'documents');
      }
      _visitForm.value = visitFormSchema;

      setTokenInSentryContext();
    }

    function setTokenInSentryContext () {
      addCheckinInformationToSentry(_visitToken.value);
    }

    function clear () {
      _visitSettings.value = null;
      _visitToken.value = '';
      _visitForm.value = {} as IVisitForm;
    }

    return {
      _visitSettings,
      visitSettings,
      _visitToken,
      visitToken,
      _visitForm,
      visitForm,
      loadVisitConditions,
      clear,
      setTokenInSentryContext,
      registerDenyOnValidator,
      executeDenyOnValidators,
      denyOnValidators,
    };
  },
  {
    persist: {
      paths: [
        '_visitSettings',
        'visitSettings',
        '_visitToken',
        'visitToken',
        '_visitForm',
        'visitForm',
      ],
      afterRestore: (ctx) => {
        ctx.store.setTokenInSentryContext();
      },
      debug: true,
    },
  },
);
