const it = {
  'welcomeScreenHeading': 'Benvenuto',
  'requiredCheckboxMessage': 'Si prega di confermare la casella di controllo',
  'pleaseSelect': 'Si prega di selezionare...',
  'cancel': 'Annulla',
  'ok': 'OK',
  'yes':'Sì',
  'back': 'Indietro',
  'welcome': 'Benvenuto',
  'no':'No',
  'pleaseEnter': 'Si prega di inserire...',
  'thanksForYourCheckin': 'Grazie per esserti registrato.',
  'checkoutConfirmTitle': 'Conferma logout',
  'checkoutConfirmMessage': 'Si prega di eseguire il logout solo quando si ha finito la visita.',
  'yesCheckout': 'Logout adesso',
  'de': 'Deutsch',
  'en': 'English',
  'es': 'Español',
  'fr': 'Français',
  'it': 'Italiano',
  'pt': 'Português',
  'jp': '日本語',
  'nl': 'Nederlands',
  'pl': 'Polski',
  'ru': 'Русский',
  'tr': 'Türkçe',
  'cn': '中国人',
  'hu': 'Magyarország',
  'cz': 'Česká republika',
  'si': 'Slovenija',
  'ua': 'Україна (Ukrayina)',
  'ro': 'România',
  'hr': 'Hrvatska',
  'thanksForYourVisit': 'Grazie per la visita.',
  'youCanCloseThisPageNow': 'Ora puoi chiudere questa pagina.',
  'checkedOutViewTitle': 'Sei stato disconnesso',
  'checkoutMessage': 'Ti stiamo disconnettendo...',
  'checkout': 'Disconnettersi',
  'invalidNameFormat': 'Si prega di inserire un nome completo valido.',
  'clickToSign': 'clicca per firmare',
  'invalidQrCodeHeading': 'Codice QR non valido',
  'invalidQrCodeText': 'Si prega di contattare il personale di accoglienza.',
  'checkedOut': 'Disconnesso',
  'checkinDeniedHeading': 'Purtroppo non possiamo registrarti.',
  'next': 'Avanti',
  'groupSelectionHeading': 'Seleziona un gruppo di visitatori:',
  'checkinMessage': 'Ti stiamo registrando...',
  'aServiceOf': 'Un servizio di',
  'checkedIn': 'Registrato',
  'CheckedInInfo': 'Si prega di lasciare questa pagina aperta per eseguire il logout dopo la visita.',
  'checkin': 'Registrati',
  'delete': 'Elimina',
  'pleaseCheckYourInput': 'Si prega di controllare i dati inseriti',
  'signAndProceed': 'Firma e procedi',
  'signatureRequiredMessage': 'Si prega di firmare il documento',
  'requiredField': 'Campo obbligatorio',
  'invalidEmailMessage': 'Si prega di inserire un indirizzo email valido',
  'pleaseEnterANumber': 'Si prega di inserire un numero',
  'pleaseEnterAValidNumber': 'Si prega di inserire un numero intero valido',
  'invalidUrlMessage': 'Si prega di inserire un URL valido, ad esempio https://www.esempio.com',
  'functionsError.noSubscriptionFound': 'Al momento non è possibile registrarti. Si prega di contattare il personale di accoglienza.',
  'checkinLoading': 'Registrazione in corso...',
  'functionsError.denyOnValidationFailed': 'Non possiamo registrarti a causa dei dati forniti. Si prega di contattare il personale di accoglienza.',
  'functionsError.missingRequiredInput': 'Poiché non hai compilato tutti i campi obbligatori, non possiamo registrarti. Si prega di contattare il personale di accoglienza.',
  'functionsError.invalidInputData': 'Hai inserito dati non validi. Si prega di contattare il personale di accoglienza.',
  'functionsError.checkinTokenExpired': 'Il link di registrazione è scaduto. Si prega di scansionare nuovamente il codice QR o di contattare il personale di accoglienza.',
  'startCamera': 'Tocca per attivare la fotocamera.',
  'cameraLoading': 'Caricamento...',
  'photoSectionTitle': 'Si prega di scattare una foto di te stesso per la registrazione.',
  'photoSectionDescription': 'L\'accesso alla fotocamera e la foto saranno utilizzati esclusivamente per verificare la tua identità durante la registrazione.',
  'takePhoto': 'Scatta foto',
  'reTakePhoto': 'Scatta nuovamente',
  'cameraForbidden': 'Accesso alla fotocamera negato.',
  'fullname': 'Nome completo',
  'Details': 'Dettagli',
};

export { it };
